import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import toasts from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import jsCookie from "js-cookie";
import { UserContext } from 'UserContext'; // Importa o contexto do usuário
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signInImage.png";

function SignIn() {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setUser, setToken } = useContext(UserContext);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const showToast = (message, type) => {
    toasts(message, { className: `toast-message ${type}`, position: 'top-right' });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (usuario === '' || senha === '') {
      showToast('Preencha todos os campos!', 'error');
      setLoading(false);
      return;
    }

    if (usuario.length < 3 || senha.length < 3) {
      showToast('Usuário ou senha inválidos!', 'error');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://weed.appreconhecimento.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: usuario,
          password: senha,
        }),
      });

      const res = await response.json();
      if (res.status === 'error') {
        showToast(res.message, 'error');
        setLoading(false);
      } else if (res.status === 'success' && res.data.token) {
        jsCookie.set('token', res.data.token, { expires: 100 });
        setToken(res.data.token);
        
        const userResponse = await fetch('https://weed.appreconhecimento.com/user', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + res.data.token,
          },
        });

        const userRes = await userResponse.json();
        if (userRes.status === 'error') {
          showToast('Erro ao obter dados do usuário!', 'error');
          setLoading(false);
        } else if (userRes.status === 'success') {
          setUser(userRes.data);
          showToast('Logado com sucesso!', 'success');
          history.push('/dashboard');
        }
        setLoading(false);
      } else {
        showToast('Erro ao conectar com o servidor!', 'error');
        setLoading(false);
      }
    } catch (error) {
      showToast('Erro ao conectar com o servidor!', 'error');
      setLoading(false);
    }
  };

  return (
    <CoverLayout
      title="Seja bem vindo"
      color="white"
      description="Por favor insira seu acesso para utilizar a plataforma"
      premotto="Ferramentas inteligentes para utilizar no dia-dia"
      motto="Power Team"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Usuário
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="text"
              placeholder="Seu usuário..."
              fontWeight="500"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Senha
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Sua senha..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Lembrar acesso
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth type="submit" disabled={loading}>
            {loading ? "Carregando..." : "ENTRAR"}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Não possui uma conta?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Crie uma
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
