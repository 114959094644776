import React, { createContext, useState, useEffect } from 'react';
import jsCookie from "js-cookie";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(jsCookie.get('token') || null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token) {
      fetch('https://weed.appreconhecimento.com/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 'success') {
            setUser(data.data);
          } else {
            setUser(null);
          }
        })
        .catch(() => {
          setUser(null);
        });
    }
  }, [token]);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken }}>
      {children}
    </UserContext.Provider>
  );
};